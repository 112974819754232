@import "../ui-kit/src/scss/variables";

.title {
  color: $standard-grey;
  font-weight: 400;
  font-size: 1.75rem;
  margin: 0;
}

.closeButtonContainer {
  margin: -1rem -1rem 0 auto;
}

.closeButton {
  cursor: pointer;
  color: $idle-gray;
  font-weight: 300;
  font-size: 1.25rem;
  padding: 0.25rem;
}

.modalContainer {
  padding: 1.5rem 3rem;
}

.buttonText {
  color: $standard-grey;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0;
}

.deleteKeyButton {
  color: $idle-gray;
  cursor: pointer;
  font-size: 0.875rem;
}

.separator {
  margin-top: 1.313rem;
  margin-bottom: 0;
}

.itemTitle {
  font-size: 0.875rem;
  color: $standard-grey;
}

.itemSubtitle {
  font-size: 0.75rem;
  color: $standard-grey;
}
