@import "../ui-kit/src/scss/variables";

$coreui-icons-font-path: "~@coreui/icons/fonts";
@import "~@coreui/icons/scss/coreui-icons";
@import '~flag-icon-css/sass/flag-icon';
@import '~simple-line-icons/scss/simple-line-icons';


@import "~@coreui/coreui/scss/coreui.scss";
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

@import "../ui-kit/src/scss/style.scss";

@import "custom";
