@import '../scss/variables';

$arrow-size: 0.375rem;
$arrow-border-width: 1px;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: max-content;
  position: relative;

  &:has(.tooltipPlacementBottomRight), &:has(.tooltipPlacementTopRight) {
    justify-content: flex-start;
  }
}

.tooltip {
  z-index: 999;
  width: max-content;
  padding: 0.375rem 0.5rem;
  border-radius: 0.625rem;
  border: 0.5px solid;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
  position: absolute;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;

  &.tooltipModeHelp {
    padding: 0.5rem 0.625rem;
  }

  &Text {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &Arrow {
    &::before, &::after {
      content: "";
      width: 0;
      height: 0;
      border-top: $arrow-size solid;
      border-right: $arrow-size solid;
      border-left: $arrow-size solid;
      position: absolute;
    }
  }


  &Placement {
    &Top, &TopRight {
      top: 0;
      transform: translateY(calc(-100% - #{$arrow-size}));

      &.tooltipArrow {
        &::before, &::after {
          top: 100%;
          left: calc(50% - #{$arrow-size});
        }

        &::after {
          margin-top: -$arrow-border-width;
        }
      }
    }

    &Bottom, &BottomRight {
      bottom: 0;
      transform: translateY(calc(100% + #{$arrow-size}));

      &.tooltipArrow {
        &::before, &::after {
          bottom: 100%;
          left: calc(50% - #{$arrow-size});
          rotate: 180deg;
        }

        &::after {
          margin-bottom: -$arrow-border-width;
        }
      }
    }

    &Left {
      left: 0;
      transform: translateX(calc(-100% - #{$arrow-size}));

      &.tooltipArrow {
        &::before, &::after {
          left: calc(100% - #{$arrow-size} / 2);
          top: calc(50% - #{$arrow-size} / 2);
          rotate: 270deg;
        }

        &::after {
          margin-left: -$arrow-border-width;
        }
      }
    }

    &Right {
      right: 0;
      transform: translateX(calc(100% + #{$arrow-size}));

      &.tooltipArrow {
        &::before, &::after {
          right: calc(100% - #{$arrow-size} / 2);
          top: calc(50% - #{$arrow-size} / 2);
          rotate: 90deg;
        }

        &::after {
          margin-right: -$arrow-border-width;
        }
      }
    }
  }

  &Mode {
    &Dark {
      color: $white;
      border-color: $idle-gray;
      background-color: $standard-grey;

      &.tooltipArrow {
        &::before {
          // arrow border color
          border-color: $idle-gray transparent transparent;
        }

        &::after {
          // arrow background color
          border-color: $standard-grey transparent transparent;
        }
      }
    }

    &Light {
      color: $standard-grey;
      border-color: $idle-gray;
      background-color: $white;

      &.tooltipArrow {
        &::before {
          // arrow border color
          border-color: $idle-gray transparent transparent;
        }

        &::after {
          // arrow background color
          border-color: $white transparent transparent;
        }
      }
    }

    &Help {
      color: $standard-grey;
      border-color: $white;
      background-color: $white;

      &.tooltipArrow {
        &::before {
          // arrow border color
          border-color: $white transparent transparent;
        }

        &::after {
          // arrow background color
          border-color: $white transparent transparent;
        }
      }
    }

    &Warning {
      color: $standard-grey;
      border-color: $warning-yellow;
      background-color: $alternate-yellow-a;

      &.tooltipArrow {
        &::before {
          // arrow border color
          border-color: $warning-yellow transparent transparent;
        }

        &::after {
          // arrow background color
          border-color: $alternate-yellow-a transparent transparent;
        }
      }
    }

    &Danger {
      color: $standard-grey;
      border-color: $error-red;
      background-color: $alternative-red-a;

      &.tooltipArrow {
        &::before {
          // arrow border color
          border-color: $error-red transparent transparent;
        }

        &::after {
          // arrow background color
          border-color: $alternative-red-a transparent transparent;
        }
      }
    }
  }
}
