@import "../../scss/variables";

.invalid {
  outline: $error-red solid 2px;
  outline-offset: -0.5px;
  transition: outline-color 0.25s ease-out;
  border-radius: 10px;

  &:active, &:focus, &:hover {
    outline: none;
  }
}
