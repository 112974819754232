@import "../../scss/variables";

.smallFont {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.dropdownMenuButton {
  margin-block: 2px;
  &:hover i, &Active i {
    font-weight: 900 !important;
  }
}

.dropdownMenuIcon {
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  line-height: 1.25rem !important;
}

.userIcon {
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  line-height: 1.25rem !important;

  &Active {
    font-weight: 900 !important;
  }
}

.chevronIcon {
  font-size: 0.5rem !important;
  font-weight: 300 !important;
  line-height: 1.25rem !important;
}

.dropdownOptionHover:hover {
  & i {
    transition: font-weight 0.25s ease-out;
    font-weight: 900 !important;
  }
}
