@import "../ui-kit/src/scss/variables";

.modal {
  min-width: 34rem;
}

.input {
  width: 188px;
}

.errorText {
  color: $error-red;
}
