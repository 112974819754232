@import "../../ui-kit/src/scss/variables";

.cardContainer {
  width: 100%;
  height: 100%;
  padding: 2rem;
}

.noContentContainer {
  padding: 8rem;
}

.outerCircle {
  background-color: $alternative-purple-b;
  width: 104px;
  height: 104px;
  border-radius: 52px;
}

.innerCircle {
  background-color: $alternative-purple-a;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.15);
  width: 88px;
  height: 88px;
  border-radius: 44px;
}

.icon {
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 57px;
  text-align: center;
  color: $white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.title {
  font-weight: 600;
  color: $black-grey;
}

.paragraph {
  color: $black-grey;
  font-size: 18px;
}

