@import "../../../../ui-kit/src/scss/variables";

.dropdownContainer {
  margin-right: 0.625rem;
}

.smallFont {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.dropdownMenuButton {
  margin-block: 2px;

  &:hover i, &Active i {
    font-weight: 900 !important;
  }

  &Active {
    color: $standard-grey;
    background-color: $accent-purple;
    outline: none;
  }
}

.dropdownMenuIcon {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
}

.userIcon {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;

  &Active {
    font-weight: 900;
  }
}

.chevronIcon {
  font-size: 0.5rem;
  font-weight: 300;
  line-height: 1.25rem;
}

.dropdownActiveIcon {
  font-weight: 900;
  font-size: 1rem !important;
  line-height: 1.25rem;
  color: $purple;
}

.dropdownInactiveIcon {
  font-weight: 100;
  font-size: 1rem !important;
  line-height: 1.25rem;
  color: $message-gray;
}

.dropdownItemIcon {
  font-size: 0.875rem !important;
  font-weight: 300;
  line-height: 1.25rem;
}

.dropdownOptionHover:hover {
  & i {
    transition: font-weight 0.25s ease-out;
    font-weight: 900 !important;
  }
}
