@import "../../scss/variables";

.container {
  padding-block: 4rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.toggleIconContainer {
  position: absolute;
  right: 25px;
  top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  cursor: pointer;
}

.toggleIcon {
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: $idle-gray;
}

.title {
  margin-block: 1rem 0;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: $standard-grey;
}

.subtitle {
  margin-block: 0.5rem 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  color: $idle-gray;
}
