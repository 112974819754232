@import "../../../ui-kit/src/scss/variables";

.browseInput {
  width: 110px;
  height: 32px;
  overflow: hidden;
  position: absolute;
  left: 70px;
  opacity: 0;
}

.browseButton {
  position: absolute;
  left: 70px;
  width: 110px;
  height: 32px;
  font-size: 14px;
  padding: 0 12px;
}

.grayMessageText {
  font-size: 14px;
  color: $message-gray;
}

.extendedHr {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.subTitle {
  font-size: 18px;
  color: $black;
}

.userFormLabel {
  width: 80px;
}

.uploadButtonContainer:hover {
  .browseButton {
    background-color: $black-grey;
    border-color: $black-grey;
  }
}

.errorText {
  color: $error-red;
}

.removeLogoButton {
  border: none;
  background: none;
  color: $brand-purple;
  font-weight: 600;
  font-size: 14px;
  text-decoration-line: underline;

  &:hover {
    color: $black-grey;
  }
}