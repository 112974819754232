/*
 * Force Bootstrap v4 transitions
 * (ignores prefers-reduced-motion media feature)
 * https://gist.github.com/robssanches/33c6c1bf4dd5cf3c259009775883d1c0
   Vlad: I haven't found a way to disable this setting in Chrome on Ubuntu 18,
   so I had to put this. Having the transitions wait and no animation showing ruins
   the app experience.

   If we need to implement better a11y (accessibility) features, we'll remove them
   But until then, we should keep those rules.

 */


.fade {
  transition: opacity 0.15s linear !important;
}

.collapsing {
  transition: height 0.35s ease !important;
}

.custom-switch .custom-control-label::after {
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out !important;
}

.custom-range::-webkit-slider-thumb {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.custom-range::-moz-range-thumb {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.custom-range::-ms-thumb {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.badge {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.progress-bar {
  transition: width 0.6s ease !important;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite !important;
  animation: progress-bar-stripes 1s linear infinite !important;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out !important;
  transform: none !important;
}

.carousel-indicators li {
  transition: opacity 0.6s ease !important;
}

.form-control {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.btn {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}