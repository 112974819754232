
.no-content {
  &.card-spaced {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .faded-icon {
    color: white;
    font-size: 3.1em;
  }

  .rounded-inner {
    width: 90px;
    height: 90px;
    background-color: $disabled-gray;
  }

  .rounded-background {
    display: flex;
    border-radius: 50%;
    align-items: center;
  }

  .rounded-outer {
    width: 104px;
    height: 104px;
    background-color: $light-grey;
    margin-bottom: 24px;
    margin-top: 20px;
  }

  .text-light-gray {
    color: $message-gray;
  }

  .text-family-lato {
    font-family: Lato, sans-serif;
  }
}


.rounded-table-row {
  transition: all 0.25s ease-out;
  display: flex;
  border: 1px solid $border-gray;
  flex-direction: row;
  margin-top: 0.5rem;
  color: black;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: Lato, sans-serif;
  align-items: center;
  height: 48px;
  cursor: pointer;

  &.state-running {

    .icon-holding-table-cell {
      width: 10%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      padding: 0.35rem;
    }

    .circle-icon {
      width: 160px;
    }
  }

  &.state-incomplete {

    background-color: $primary-light;

    &:hover {
      background-color: darken($primary-light, 5%);
    }

    .icon-holding-table-cell {
      width: 10%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      padding: 0.35rem;
    }

    .more-info-needed-button {
      text-align: center;
      padding: 0 12px;

    }

    .collection-warning-icon {
      .warning-icon-inner {
        width: 1rem;
        height: 1rem;
        background-color: white;
      }

      .rounded-background {
        display: flex;
        border-radius: 50%;
        align-items: center;
      }

      .warning-icon-outer {
        width: 2rem;
        height: 2rem;
        background-color: $alternative-purple-b;
        margin-right: 8px;
      }

      .warning-icon-mid {
        width: 1.5rem;
        height: 1.5rem;
        background-color: $alternative-purple-a;

      }
    }

    .unfinished-collection-warning {
      font-size: 0.70rem;
      color: $alternative-purple-a;
    }
  }

  &.state-success {
    .collection-date {
      text-align: left;
    }

    &:hover {
      background-color: $accent-purple-2;
    }

    .icon-holding-table-cell {
      width: 10%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      padding: 0.35rem;
    }

    .circle-icon {
      width: 160px;
    }
  }


  &.state-selected {

    background-color: $alternative-purple-b;

    .icon-holding-table-cell {
      width: 10%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      padding: 0.35rem;
    }

    .circle-icon {
      width: 160px;
    }
  }

  &.state-failed {

    background-color: $alternative-red-c;

    &:hover {
      background-color: darken($alternative-red-c, 5%);
    }

    .icon-holding-table-cell {
      width: 10%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      padding: 0.35rem;
    }

    .circle-icon {
      width: 160px;
    }
  }
}

.right-margin-small {
  margin-right: 10px;
}