$border-gray: #DDDDDD;

.navbar-nav {
  .nav-link {
    &:hover, &:focus {
      color: $white;
    }
  }
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

a {
  -webkit-transition: color 0s !important;
  transition: color 0s !important;
}

header.app-header.navbar {
  background-color: var(--primary);
  border-bottom: 0;
}


.dropdown .btn.context-menu-trigger {
  background-color: transparent;
  box-shadow: 0 0 0;
  border: 0;
  color: $primary;

  &:hover {
    background-color: transparent;
  }
}

.blured-navbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  filter: blur(5px);
}

.sidebar .nav {
  width: 100%;
}

.sidebar .sidebar-nav {
  background: white;
  padding-inline: 12px;
}

.sidebar-minimized .sidebar-nav {
  padding: 0;
}

@import "./toastify";
@import "./pages/collections";
@import "./pages/products";

@import "./components/collection_table/empty";
@import "./components/collection_table/row";
@import "./components/file_input";
@import "./collection-modal";

@import "./animation-fixes";
