@import "../../scss/variables";


.companyOption {
  border-top-color: $primary;
  border-top-style: solid;
  border-top-width: 2px;

  border-top-left-radius: 0;
  border-top-right-radius: 0;

  cursor: pointer;

  transition: all 0.1s linear;

  &:hover {
    background-color: $light-grey;
  }

}

.disabled {
  background-color: $light-grey;
  cursor: unset !important;

    border-top-color: $secondary;
}
