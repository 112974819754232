@import "../scss/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin-block: 1rem 0;
  font-size: 1.75rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 400;
  color: $message-gray;
}

.subtitle {
  margin-block: 0.75rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  font-weight: 400;
  color: $message-gray;
}
