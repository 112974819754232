@import "../../../scss/variables";

.instructionsText {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140.62%;
  color: $black-grey
}

.emailInput {
  width: 13rem;
}

.separator {
  width: 100%;
  height: 0.5px;
  background-color: $weak-grey;
  margin-block: 1.5rem;
}
