@import "../../../scss/variables";

.passwordInput {
  width: 13rem;
}

.tooltipIcon {
  font-weight: 100;
  font-size: 1rem;
  color: $idle-gray;
}
