
.no-content {
  &.card-spaced {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .faded-icon {
    color: white;
    font-size: 2em;
  }

  .rounded-inner {
    width: 60px;
    height: 60px;
    background-color: $disabled-gray;
  }

  .rounded-background {
    display: flex;
    border-radius: 50%;
    align-items: center;
  }

  .rounded-outer {
    width: 70px;
    height: 70px;
    background-color: $light-grey;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .text-light-gray {
    color: $message-gray;
  }

  .text-family-lato {
    font-family: Lato, sans-serif;
  }
}


.right-margin-small {
  margin-right: 10px;
}
