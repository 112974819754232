@import "../../scss/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 4rem 3rem;
  width: 34rem;
  background-color: $accent-gray;
  border-radius: 1rem;
}

.spinner {
  font-weight: 400;
  font-size: 2.5rem;
}

.title {
  margin-top: 0.75rem;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  color: $standard-grey;
}

.infoText {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $idle-gray;
}

.button {
  border: solid $weak-grey 0.5px;
  box-shadow: none;
}

.buttonText {
  font-weight: 400;
  font-size: 0.75rem;
  color: $idle-gray;
}
