
.custom-file-upload-input {
  padding: 16px;
  border-radius: 8px;

  &.loading {
    background-color: $accent-purple;

    .purple-color {
      color: $dark; //I know that there is a similar class already but it uses !Important and cancels the colour change of the hover
    }

    &:hover {
      background-color: darken($accent-purple, 2.5%);
    }
  }


  &:hover {
    background-color: $accent-purple-2;

    .purple-color {
      color: $black-grey
    }
  }

  &.force-hover {
    background-color: $accent-purple-2;

    .purple-color {
      color: $black-grey
    }
  }

  .dashed-inner-border-box {
    background-image: repeating-linear-gradient(0deg, $primary-purple, $primary-purple 17px, transparent 17px, transparent 30px, $primary-purple 30px),
    repeating-linear-gradient(90deg, $primary-purple, $primary-purple 17px, transparent 17px, transparent 30px, $primary-purple 30px),
    repeating-linear-gradient(180deg, $primary-purple, $primary-purple 17px, transparent 17px, transparent 30px, $primary-purple 30px),
    repeating-linear-gradient(270deg, $primary-purple, $primary-purple 17px, transparent 17px, transparent 30px, $primary-purple 30px);
    background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    border-radius: 5px;

    &:hover {
      background-image: repeating-linear-gradient(0deg, $black-grey, $black-grey 17px, transparent 17px, transparent 30px, $black-grey 30px),
      repeating-linear-gradient(90deg, $black-grey, $black-grey 17px, transparent 17px, transparent 30px, $black-grey 30px),
      repeating-linear-gradient(180deg, $black-grey, $black-grey 17px, transparent 17px, transparent 30px, $black-grey 30px),
      repeating-linear-gradient(270deg, $black-grey, $black-grey 17px, transparent 17px, transparent 30px, $black-grey 30px);
    }

    &.force-hover {
      background-image: repeating-linear-gradient(0deg, $black-grey, $black-grey 17px, transparent 17px, transparent 30px, $black-grey 30px),
      repeating-linear-gradient(90deg, $black-grey, $black-grey 17px, transparent 17px, transparent 30px, $black-grey 30px),
      repeating-linear-gradient(180deg, $black-grey, $black-grey 17px, transparent 17px, transparent 30px, $black-grey 30px),
      repeating-linear-gradient(270deg, $black-grey, $black-grey 17px, transparent 17px, transparent 30px, $black-grey 30px);
    }

  }

  .collection-upload-icon {
    font-size: 65px;
  }

  .purple-color {
    color: $primary-purple; //I know that there is a similar class already but it uses !Important and cancels the colour change of the hover
    font-weight: 600;
  }

  .under-text {
    font-size: 18px;
    color: $black-grey;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.remove-icon {
  color: $primary;
  cursor: pointer;
  position: relative;
  z-index: 1000;

  &:hover {
    color: darken($primary, 20%);
  }
}