@import "../../../scss/variables";

.error {
  color: $error-red;
}

.formContainer {
  width: 31.875rem;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.labelInputContainer {
  margin-bottom: 0.625rem;
}

.baseField {
  margin-left: 0.75rem;
}

.companyNameField {
  @extend .baseField;
  min-width: 9.5rem;
  max-width: 9.5rem;
}

.vatField {
  @extend .baseField;
  min-width: 9.25rem;
  max-width: 9.25rem;
}

.addressField {
  @extend .baseField;
  min-width: 9.5rem;
  max-width: 9.5rem;
}

.postNumberField {
  @extend .baseField;
  min-width: 4.5rem;
  max-width: 4.5rem;
}

.cityField {
  @extend .baseField;
  min-width: 9.5rem;
  max-width: 9.5rem;
}

.nameField {
  @extend .baseField;
  min-width: 9.5rem;
  max-width: 9.5rem;
}

.emailField {
  @extend .baseField;
  min-width: 13rem;
  max-width: 13rem;
}

.phoneNumberField {
  @extend .baseField;
  min-width: 9.5rem;
  max-width: 9.5rem;
}

.passwordField {
  @extend .baseField;
  min-width: 13rem;
  max-width: 13rem;
}

.labelInput {
  margin-block: auto;
  color: $standard-grey;
  font-size: 0.875rem;
  text-align: right;
  white-space: nowrap;

  &Company {
    @extend .labelInput;
    min-width: 6rem;
  }

  &Personal {
    @extend .labelInput;
    min-width: 3.75rem;
  }
}

.termsAndConditions {
  color: $standard-grey;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.errorField {
  outline-color: $error-red !important;
}

.tooltipIcon {
  font-weight: 100;
  font-size: 1rem;
  color: $idle-gray;
}

.separator {
  width: 100%;
  height: 0.5px;
  background-color: $weak-grey;
  margin-block: 1.5rem;
}
