@import "../scss/variables";

$dropdown-menu-width: 13.5rem;

.caret {
  margin-left: 0.25rem;
  font-size: 0.625rem;
}

.hr {
  margin: 0.375rem 0;
}

.dropdownContainer {
  display: inline-block;
  position: relative;
  color: $black-grey;
  width: fit-content;
}

.dropdownMenu {
  position: absolute;
  overflow: visible;
  background: $white;
  box-shadow: 0.5px 0.5px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  z-index: 999;
  font-size: 0.75rem;
  width: $dropdown-menu-width;
  padding-block: 0.375rem;
}

.dropdownMenuItemContainer {
  min-height: 1.8rem;
  max-height: 17.25rem;
  overflow-y: auto;
}

.dropdownMenuTopPositioning {
  top: auto;
  bottom: 100%;
  margin-bottom: 0.25rem;

}

.dropdownMenuLeftPositioning {
  left: auto;
  right: 0;
}

.dropdownSubMenuLeftPositioning {
  right: $dropdown-menu-width * 2 - 0.25rem;
}

.multiDropdownMenu {
  position: absolute;
  background: $white;
  box-shadow: 0.5px 0.5px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  z-index: 999;
  font-size: 0.75rem;
  width: $dropdown-menu-width;
  transform: translateX($dropdown-menu-width - 0.5rem);
  top: 0;
  padding-block: 0.375rem;
}

.header {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.75rem;
  padding: 0 0.75rem 0.4375rem 0.75rem;
  cursor: default;
}

.selectableOption {
  cursor: pointer;
  padding: 0.3125rem 0.75rem;
  transition: background-color 0.25s ease-out;

  i {
    font-size: 0.75rem;
    margin-right: 0.75rem;
  }

  img {
    width: 0.75rem;
    margin-right: 0.75rem;

  }

  &:hover {
    background: $accent-purple;
  }
}

.multiOption {
  display: flex;
  align-items: center;
  padding: 0.3125rem 0.75rem;
  cursor: pointer;
  transition: all 0.25s ease-out;

  i {
    font-size: 0.75rem;
  }

  img {
    width: 1rem;
  }

  &:hover {
    background: $accent-purple;
  }
}

.filter {
  margin: 0.75rem;
}

.examplesDropdownMenu {
  position: absolute;
  background: $white;
  box-shadow: 0.5px 0.5px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  z-index: 999;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  width: 19.5rem;
}

.exampleOption {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.3125rem 0.75rem;
  transition: all 0.25s ease-out;

  &:hover {
    background: $accent-purple;
  }
}

.examplesHeader {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.75rem;
  padding: 0 0.75rem 0.4375rem 0.75rem;
  cursor: default;
}

.example {
  color: $idle-gray;
}

.accordionDropdownMenu {
  position: absolute;
  background: $white;
  box-shadow: 0.5px 0.5px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  z-index: 999;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  width: $dropdown-menu-width;
  max-height: 20rem;
  overflow-y: auto;
}

.accordionOptionContainer {
  display: flex;
  padding-left: 0.75rem;
  align-items: center;
  transition: all 0.25s ease-out;

  &:hover {
    background: $accent-purple;
  }
}

.accordionOption {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.3125rem 0.75rem 0.3125rem 0.25rem;
  flex-grow: 1;
}

.optionCheckbox {
  margin-left: 0.375rem;
}

.suboptionsLabel {
  color: $idle-gray;
}

.accordionSuboptions {
  margin: 0.3125rem 0;
}

.suboption {
  padding: 0.3125rem 0.75rem 0.3125rem 2rem;
  cursor: pointer;
  transition: all 0.25s ease-out;

  &:hover {
    background: $accent-purple;
  }
}

.disabled {
  cursor: not-allowed;
  color: $weak-grey;

  &:hover {
    background: none;
  }
}

.focus {
  background: $weak-grey !important;
  outline: 0.5px solid $weak-grey !important;
}

.borderedToggle {
  border: 0.5px solid $standard-grey;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  outline: none;

  &:hover {
    border: 0.5px solid $standard-grey;

  }
}
