
.order-confirmation-upload-modal {

  margin-top: 200px;

  .cancel-link {
    z-index: 10000;
    // the default link underline is too close to the text, so we "simulated" it with a border
    // and removed the original underline
    border-bottom: 1px solid $primary;

    &:hover {
      text-decoration: none;
    }
  }

  .is-loading {
    background-color: $accent-purple;
  }
}