@import "../../src/scss/variables";

.icon {
  font-size: 1rem;
}

.activeIcon {
  font-weight: 900 !important;
  color: $standard-grey;
}

.inactiveIcon {
  font-weight: 300 !important;
  color: $idle-gray;
}

.disabledLink {
  cursor: default;
  border: none !important;

  &:hover {
    background: transparent !important;
    border: none !important;
  }

  & .linkTitle, & .icon {
    color: $disabled-gray !important;
  }
}

// Extended sidebar
:global(.ps-container) {
  .linkContainer {
    margin-bottom: 0.125rem;
    user-select: none;
  }

  .link {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    border: 0.5px solid transparent;
    text-decoration: none;
    &:not(.activeLink):hover {
      background: $light-grey;
      border-radius: 8px;
    }
  }

  .activeLink {
    background: $weak-grey;
    border-radius: 10px;
  }

  .iconTooltipWrapper {
    display: none;
  }

  .noTooltipIcon {
    width: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .linkTitle {
    display: inline;
    margin-left: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $standard-grey;
    white-space: nowrap;
  }

  .navTitle {
    margin-block: 1rem 0.5rem;
    margin-left: 0.75rem;
    font-size: 0.625rem;
    line-height: 1rem;
    color: $message-gray;
  }
}

// Minimized sidebar
:global(.sidebar-minimized) {
  .linkContainer {
    height: 2rem;
    margin-bottom: 0.125rem;
    display: flex;

    &:nth-child(2) {
      margin-top: 0.625rem;
    }
  }

  .link {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(.activeLink):hover {
      background: $light-grey;
      border-width: 0.5px 0;
    }
  }

  .activeLink {
    background: $weak-grey;
    border-width: 0.5px 0;
  }

  .iconTooltipWrapper {
    width: 100%;
    height: 100%;
  }

  .noTooltipIcon {
    display: none;
  }

  .linkTitle {
    display: none;
  }

  .navTitle {
    display: none;
  }

  .separator {
    width: 1.125rem;
    height: 0;
    margin-block: 0.4rem;
    margin-inline: auto;
    border-bottom: 1px solid $message-gray;
  }
}
