@import "../scss/variables";

.checkboxContainer {
  position: relative;
  padding-left: 1.5rem;

  .label {
    min-height: 1rem;
    position: relative;
    vertical-align: top;
    margin-bottom: 0;

    &.disabled {
      &::before {
        border-color: $weak-grey;
        background-color: transparent;
      }
    }

    &::before {
      background-color: #FFFFFF;
      border: $idle-gray solid 1px;
      border-radius: 2.25px;
      height: 1rem;
      width: 1rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1.5rem;
      pointer-events: none;
      content: "";
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    }

    &::after {
      height: 1rem;
      width: 1rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1.5rem;
      display: block;
      content: "";
      background: no-repeat 50% / 50% 50%;
    }

  }

  .labelHover::before {
    border-color: $alternative-purple-a;
    box-shadow: 0 0 2px 2px $accent-purple;
  }

  .input {
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 1rem;
    height: 1rem;
    overflow: visible;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &:checked ~ .label::after {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg viewBox='6.888 5.744 1.977 1.4653' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 8.799 6.121 C 8.887 6.033 8.887 5.898 8.799 5.81 C 8.711 5.722 8.575 5.722 8.487 5.81 L 7.625 6.673 L 7.265 6.321 C 7.177 6.233 7.041 6.233 6.954 6.321 C 6.866 6.409 6.866 6.545 6.954 6.633 L 7.465 7.144 C 7.553 7.231 7.689 7.231 7.776 7.144 L 8.799 6.121 Z' fill='%23FFF' style='' transform='matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)'/%3E%3C/svg%3E");
    }

    &:indeterminate ~ .label::after {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg viewBox='5.595 6.884 3.152 0.676' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 5.933 6.884 C 5.736 6.884 5.595 7.039 5.595 7.222 C 5.595 7.419 5.736 7.56 5.933 7.56 L 8.409 7.56 C 8.592 7.56 8.747 7.419 8.747 7.222 C 8.747 7.039 8.592 6.884 8.409 6.884 L 5.933 6.884 Z' fill='%23FFF' style=''/%3E%3C/svg%3E");
    }

    &:checked ~ .label::before, &:indeterminate ~ .label::before {
      border-color: $brand-purple;
      background-color: $brand-purple;
    }
  }
}
